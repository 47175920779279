import { useLayoutEffect } from "react";

export function useTokenMessageHandler(navigate, onTokenSet) {
  // List of allowed domains
  const allowedDomains = [
    "partnerqa.fundsindia.com",
    "partnerneo.fundsindia.com",
    "leafround-post-message.vercel.app",
    "www.partner.fundsindia.com",
    "partner.fundsindia.com",
    "stagingpartner.fundsindia.com",
    "staging.fundsindia.com",
  ];

  useLayoutEffect(() => {
    const handleTokenMessage = (event) => {
      if (!localStorage.getItem("token")) {
        // Extract hostname from the event origin
        try {
          const originDomain = new URL(event.origin).hostname;

          if (allowedDomains.includes(originDomain)) {
            if (event.data?.type === "CHECK_READY") {
              event.source.postMessage({ type: "READY" }, event.origin);
            } else if (event.data?.type === "AUTH_TOKEN" && event.data?.token) {
              const { token } = event.data;
              localStorage.setItem("token", token);
              onTokenSet(token); // Trigger validation when token is set
            }
          }
        } catch (error) {
          console.error("Invalid origin:", event.origin, error);
        }
      }
    };

    window.addEventListener("message", handleTokenMessage);

    return () => {
      window.removeEventListener("message", handleTokenMessage);
    };
  }, [navigate, onTokenSet]);
}

import React, { useRef, useState } from 'react';
import {
  Box,
  FileUploadOutlinedIcon,
  Typography,
  WarningAmberRoundedIcon,
} from '../../../assets/mui';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { CircularProgress, Slide, styled } from '@mui/material';
import { useMobileSize } from '../../../hooks/useScreenSize';
import { FileOpenOutlined, TaskOutlined } from '@mui/icons-material';
import { NetworkManager } from '../../../services';
import clsx from 'clsx';
import { useLayoutStore } from '../../../components/organisms/Layout/store';
import { CLIENT_ONBOARDING_PAGE_TYPE } from '../../Clients/constants';

const boxStyle = {
  cursor: 'pointer',
  overflow: 'auto',
  scrollbarWidth: 'none' as 'none', // Hide the scrollbar for firefox
  '&::-webkit-scrollbar': {
    display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
  },
  '&-ms-overflow-style:': {
    display: 'none', // Hide the scrollbar for IE
  },
};

const errorStateObject = {
  1: {
    heading: 'Verification failed',
    message:
      'Aadhaar verification failed, either you can retry or you can use different method of verification.',
  },
  2: {
    heading: 'Please try again later',
    message:
      'You have reached the maximum number of tries for this method. Please try another method or contact support. We regret the inconvenience.',
  },
};

export const ErrorStateBanner = ({ count }) => {
  return (
    <div className="mt-6 border border-amber-600 flex items-start p-6 rounded-xl gap-5">
      <WarningAmberRoundedIcon className="text-amber-600" fontSize="large" />
      <div className="flex flex-col items-start gap-1">
        <p className="text-base font-medium">{errorStateObject[count].heading}</p>
        <p className="text-sm text-gray-600">{errorStateObject[count].message}</p>
      </div>
    </div>
  );
};

const UploadFileSection = ({
  isLoading,
  setIsLoading,
  inputFrontFile,
  setInputFrontFile,
  inputBackFile,
  setInputBackFile,
  errorFront,
  errorBack,
  setErrorBack,
  setErrorFront,
  setUploadError,
}) => {
  //   const [isLoading, setIsLoading] = useState(false);
  const fileInputFrontRef = useRef<HTMLInputElement | null>(null);
  const fileInputBackRef = useRef<HTMLInputElement | null>(null);
  //   const [inputFile, setInputFile] = useState(null);
  const [error, setError] = useState(null);
  const [highlighted, setHighlighted] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setHighlighted(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setHighlighted(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setHighlighted(false);
  };

  const handleFrontDrop = (e) => {
    e.preventDefault();
    setHighlighted(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    if (droppedFiles?.length > 1) {
      setErrorFront(() => 'Please select only single file');
      return;
    }

    const file = droppedFiles[0];
    const fileSize = ((file as File)?.size / (1024 * 1024)).toFixed(2);
    if (parseFloat(fileSize) > 5) {
      setErrorFront(() => 'Front File size should be less than 5 mb');
      setErrorBack(() => null);
      return;
    }
    setInputFrontFile(file);
  };

  const handleBackDrop = (e) => {
    e.preventDefault();
    setHighlighted(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    if (droppedFiles?.length > 1) {
      setErrorBack(() => 'Please select only single file');
      return;
    }

    const file = droppedFiles[0];
    const fileSize = ((file as File)?.size / (1024 * 1024)).toFixed(2);
    if (parseFloat(fileSize) > 5) {
      setErrorBack(() => 'Back File size should be less than 5 mb');
      setErrorFront(() => null);
      return;
    }

    setInputBackFile(file);
  };

  const onFrontFileInput = (event) => {
    setErrorBack(() => null);
    setErrorFront(() => null);
    const file = event?.target?.files?.[0];
    if (file) {
      const fileSize = (file?.size / (1024 * 1024)).toFixed(2);
      if (parseFloat(fileSize) > 5) {
        setErrorFront(() => 'Front File size should be less than 5 mb');
        return;
      }
      setInputFrontFile(() => event?.target?.files?.[0]);
    }
  };

  const onBackFileInput = (event) => {
    setErrorFront(() => null);
    setErrorBack(() => null);
    const file = event?.target?.files?.[0];

    if (file) {
      const fileSize = (file?.size / (1024 * 1024)).toFixed(2);
      if (parseFloat(fileSize) > 5) {
        setErrorBack(() => 'Back File size should be less than 5 mb');
        return;
      }
      setInputBackFile(() => event?.target?.files?.[0]);
    }
  };

  const isMobile = useMobileSize();
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <div className={`flex justify-center gap-4`} style={{ ...boxStyle, height: '100%' }}>
        {/* Front File Upload Section */}
        <div className="flex flex-col flex-1">
          <input
            ref={fileInputFrontRef}
            id="frontFile"
            type="file"
            accept=".png,.jpg,.jpeg,image/png,.pdf"
            style={{ display: 'none' }}
            onChange={onFrontFileInput}
          />
          <div className="flex flex-col gap-1 h-full">
            <div
              className={`flex flex-col justify-center items-center bg-none border-dashed border-2 border-gray-300 rounded-lg min-h-[180px] max-h-[180px] cursor-pointer ${
                highlighted ? 'border-green-50' : ''
              }`}
              style={{
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`,
              }}
              onClick={() => {
                setErrorFront(null);
                if (inputFrontFile) return;
                if (fileInputFrontRef.current) fileInputFrontRef.current.click();
              }}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleFrontDrop}
            >
              {inputFrontFile ? (
                <div className="flex gap-2 flex-col items-center">
                  <TaskOutlined fontSize="medium" htmlColor="#991B1B" />
                  <p className="text-center">
                    {inputFrontFile.name.length > 15
                      ? `${inputFrontFile.name.substring(0, 15)}...`
                      : inputFrontFile.name}
                  </p>
                  <div
                    className="flex items-center cursor-pointer hover:bg-gray-100 p-1 rounded-md"
                    onClick={() => {
                      (document.getElementById('frontFile') as HTMLInputElement).value = '';
                      setUploadError(null);
                      setErrorBack(null);
                      setErrorFront(null);
                      setInputFrontFile(null);
                    }}
                  >
                    <DeleteOutlinedIcon className="mr-1" fontSize="small" />
                    <p className="text-sm">Delete</p>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center w-full p-5">
                  <button className="min-w-[40px] w-[40px] max-w-[40px] h-[40px] p-0 rounded-md border border-gray-200 bg-white/40 shadow-sm text-black hover:bg-white/40">
                    <FileUploadOutlinedIcon />
                  </button>
                  <p className="text-gray-600 mt-2 text-center text-sm">
                    <b>Upload front photo or full document</b>
                  </p>
                  <p className="text-gray-600 text-xs text-center">Upload a file up to 5MB here</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Back File Upload Section */}
        <div className="flex flex-col flex-1">
          <input
            ref={fileInputBackRef}
            id="backFile"
            type="file"
            accept=".png,.jpg,.jpeg,image/png,.pdf"
            style={{ display: 'none' }}
            onChange={onBackFileInput}
          />
          <div className="flex flex-col gap-1 h-full">
            <div
              className={`flex flex-col justify-center items-center bg-none border-dashed border-2 border-gray-300 rounded-lg min-h-[180px] max-h-[180px] cursor-pointer ${
                highlighted ? 'border-green-50' : ''
              }`}
              style={{
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23333' stroke-width='1' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`,
              }}
              onClick={() => {
                setErrorBack(null);
                if (inputBackFile) return;
                if (fileInputBackRef.current) fileInputBackRef.current.click();
              }}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleBackDrop}
            >
              {inputBackFile ? (
                <div className="flex gap-2 flex-col items-center">
                  <TaskOutlined fontSize="medium" htmlColor="#991B1B" />
                  <p className="text-center">
                    {inputBackFile.name.length > 15
                      ? `${inputBackFile.name.substring(0, 15)}...`
                      : inputBackFile.name}
                  </p>
                  <div
                    className="flex items-center cursor-pointer hover:bg-gray-100 p-1 rounded-md"
                    onClick={() => {
                      (document.getElementById('backFile') as HTMLInputElement).value = '';
                      setUploadError(null);
                      setErrorBack(null);
                      setErrorFront(null);
                      setInputBackFile(null);
                    }}
                  >
                    <DeleteOutlinedIcon className="mr-1" fontSize="small" />
                    <p className="text-sm">Delete</p>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center w-full p-5">
                  <button className="min-w-[40px] w-[40px] max-w-[40px] h-[40px] p-0 rounded-md border border-gray-200 bg-white/40 shadow-sm text-black hover:bg-white/40">
                    <FileUploadOutlinedIcon />
                  </button>
                  <p className="text-gray-600 mt-2 text-center font-bold text-sm">
                    Click to upload back photo
                  </p>
                  <p className="text-gray-600 text-xs text-center">Upload a file up to 5MB here</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Slide>
  );
};

const verifyAadhaar = (formData: FormData, isClient: boolean) => {
  return NetworkManager.Call({
    method: 'post',
    path: NetworkManager.PATH.partnerAadhaarOCRVerification,
    data: formData,
  });
};

const AadhaarOCR = ({ setAadhaarOCRSelected, isClient, handleStep }) => {
  const [inputFrontFile, setInputFrontFile] = useState(null);
  const [inputBackFile, setInputBackFile] = useState(null);
  const [errorFront, setErrorFront] = useState(null);
  const [errorBack, setErrorBack] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [aadharUploadLoading, setAadharUploadLoading] = useState(false);
  const clientId = useLayoutStore(({ clientId }) => clientId);
  const clientIdValue = isClient ? clientId : null;
  const [isRateLimitExceeded, setRateLimitExceeded] = useState(false);

  const handleSubmit = async () => {
    // isRetry
    //   ? track(EVENT_NAMES.AADHAAR_OCR_RETRY_CLICKED)
    //   : track(EVENT_NAMES.AADHAAR_OCR_SUBMITTED);
    setIsLoading(true);
    setAadharUploadLoading(() => true);
    setErrorFront(() => null);
    setErrorBack(() => null);
    let formData = new FormData();
    if (inputFrontFile) {
      try {
        formData.append('file1', inputFrontFile);
        formData.append('file2', inputBackFile);
        isClient && formData.append('clientPublicId', clientIdValue);
        const response = await verifyAadhaar(formData, isClient);

        if (!response.error) {
          isClient
            ? handleStep(CLIENT_ONBOARDING_PAGE_TYPE.BANK_VERIFICATION)
            : handleStep(response.nextPage);
        }
        setIsLoading(() => false);
        setAadharUploadLoading(() => false);
      } catch (error) {
        if (error?.response?.status === 429) {
          setRateLimitExceeded(true);
          setErrorFront(error.response.data.message);
        } else if (error?.response?.status === 500) {
          setErrorFront('Something went wrong. Please try again later.');
          setUploadError(true);
        } else {
          setErrorFront(error.response.data.message);
          setUploadError(true);
        }
        setIsLoading(() => false);
        setAadharUploadLoading(() => false);
      }
    }
  };
  return (
    <div>
      <div>
        <UploadFileSection
          inputBackFile={inputBackFile}
          setInputBackFile={setInputBackFile}
          inputFrontFile={inputFrontFile}
          setInputFrontFile={setInputFrontFile}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setErrorBack={setErrorBack}
          setErrorFront={setErrorFront}
          errorBack={errorBack}
          errorFront={errorFront}
          setUploadError={setUploadError}
        />

        {!inputFrontFile && (
          <p className="text-gray-600 text-xs my-2">
            Note: <b>Front file is required.</b>
          </p>
        )}

        {errorBack && inputBackFile === null ? (
          <p className="text-red-500 text-xs my-1.5">{errorBack}</p>
        ) : (
          <></>
        )}

        {errorFront && (
          <Typography sx={{ color: 'red', marginTop: '8px', fontSize: '12px' }}>
            {errorFront}
          </Typography>
        )}
      </div>

      {!uploadError && !errorFront ? (
        <div>
          {/* Button Section */}
          <button
            type="submit"
            disabled={isLoading || inputFrontFile === null}
            className={clsx(
              'relative bg-green-700 mt-4 text-sm disabled:bg-green-700/60 mx-auto text-white rounded-xl font-bold py-3 w-full',
              {
                'bg-opacity-80 text-opacity-50': isLoading,
              },
            )}
            onClick={handleSubmit}
          >
            <CircularProgress
              style={{ color: 'white' }}
              className={clsx('absolute text-white left-8 md:left-12', {
                visible: isLoading,
                invisible: !isLoading,
              })}
              size={20}
            />
            Validate Aadhaar
          </button>

          {/* Loading Text */}
          {isLoading && (
            <div className="mt-2.5">
              <p className="text-slate-500 text-sm">
                Please wait, as we validate your Aadhaar. Don't close the screen or go back until
                verification is complete.
              </p>
            </div>
          )}
        </div>
      ) : uploadError ? (
        <div>
          {/* <ErrorStateBanner count={1} /> */}
          <div className={`w-full flex gap-3 ${errorFront || errorBack ? 'mt-1.5' : 'mt-4'} `}>
            <button
              className={`flex-grow text-white rounded-lg border border-green-800 bg-green-700 bg-gradient-to-b from-[rgba(255,255,255,0.18)] to-transparent shadow-sm shadow-black/10 hover:bg-green-700 hover:from-[rgba(255,255,255,0.18)] hover:to-transparent px-5 py-3 mt-1.5 ${
                inputFrontFile === null || isLoading
                  ? 'bg-slate-300 border-slate-300 text-stone-500 cursor-not-allowed'
                  : ''
              }
                
              `}
              disabled={inputFrontFile === null || isLoading}
              onClick={() => handleSubmit()}
            >
              Retry
              {isLoading && (
                <CircularProgress
                  size={14}
                  className="text-white ml-2"
                  style={{ color: 'white' }}
                />
              )}
            </button>

            <button
              className="flex-grow relative flex items-center text-black rounded-lg border border-slate-300 bg-white bg-gradient-to-b from-transparent to-black/5 justify-center px-5 py-3 mt-1 whitespace-nowrap"
              onClick={() => setAadhaarOCRSelected(false)}
            >
              Try another method
            </button>
          </div>

          {isLoading && (
            <Box sx={{ marginTop: '10px' }}>
              <p className="text-slate-500 text-sm">
                Please wait, as we validate your Aadhaar. Don't close the screen or go back until
                verification is complete.
              </p>
            </Box>
          )}
        </div>
      ) : (
        isRateLimitExceeded && <ErrorStateBanner count={2} />
      )}

      {/*     
      <ErrorStateBanner count={submitCount} />

      <Box
        sx={{
          display: 'flex',
          // justifyContent: "center",
          alignItems: 'flex-start',
          gap: '12px',
        }}
      >
        <Button
          size={'medium'}
          sx={{
            borderRadius: '12px',
            border: '1px solid #15803D',
            background:
              'linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0.00) 119.05%), #166534',
            boxShadow: '0px 1.1px 1.8px -0.3px rgba(0, 0, 0, 0.10)',
            color: '#FFF',
            '&.Mui-disabled': {
              background: baseColors.slate[300],
              border: '1px solid ' + baseColors.slate[300],
            },
            '&:hover': {
              background:
                'linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0.00) 119.05%), #166534',
              boxShadow: '0px 1.1px 1.8px -0.3px rgba(0, 0, 0, 0.10)',
            },
            padding: '18px 20px',
            width: 'fit-content',
            marginTop: 4,
          }}
          disabled={inputFrontFile === null || inputBackFile === null || isLoading}
          onClick={handleSubmit}
        >
          Validate Aadhaar
          {isLoading && (
            <CircularProgress size={14} style={{marginLeft: "8px", color: '#fff'}} />
          )}
        </Button>

        <Button
          size={'medium'}
          onClick={() => setShowOCR(false)}
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            color: 'black',
            borderRadius: '12px',
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.04) 132.14%), #FFF',
            '&:hover': {
              border: `1px solid ${baseColors.slate[300]}`,
              background:
                'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.04) 132.14%), #FFF',
            },
            justifyContent: 'center',
            padding: '18px 20px',
            width: 'fit-content',
            marginTop: 4,
          }}
        >
          Try another method
        </Button>
      </Box> */}

      <div className="mt-4 bg-stone-100 border border-stone-200 rounded-lg p-3 mb-2 flex">
        {/* Icon Box */}
        <div className="bg-stone-200 rounded-full h-fit p-1.5 mr-2">
          <FileOpenOutlined />
        </div>

        {/* Text Box */}
        <div className="flex flex-col gap-1">
          <p className="text-stone-700 font-medium">Instructions to upload</p>
          <p className="text-stone-500 text-xs font-medium">
            Kindly upload Aadhaar in JPEG/PNG/PDF format only.
          </p>

          <p className="text-stone-500 text-xs cursor-pointer">
            Note:
            <div className="ml-1.5">
              - Please don't upload password protected Aadhaar or masked Aadhaar.
            </div>
            <div className="ml-1.5">- Also make sure you upload updated Aadhaar.</div>
            <div className="ml-1.5">
              - We only store the last four digits of your Aadhaar number.
            </div>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AadhaarOCR;

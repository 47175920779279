import React, { useState } from 'react';
import { useLayoutStore } from '../../../components/organisms/Layout/store';
import OnboardingSuccess from '../../../assets/onboarding-success.png';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { PartnerOnboardingActions } from '../types';
import { PARTNER_ONBOARDING_PAGE_TYPE } from '../constants';

interface FinalStepProps extends PartnerOnboardingActions {}

const FinalStep: React.FC<FinalStepProps> = ({ handleStep }) => {
  const [copied, setCopied] = useState(false);

  const { partnerDetails, partnerId, setIsPartnerDrawerOpen } = useLayoutStore();
  // const { data, isLoading } = usePartnerVirtualAccount(partnerId);

  // const { accountNumber, bankName, branchDetails, ifscCode } =
  //     data?.data || {};

  const copyText = `Please add money to the following beneficiary to make investments on Tap Invest. (Note that money should be deposited from a bank account matching the name below.) `;
  const waText = `Please add money to the following beneficiary to make investments on Tap Invest. (Note that money should be deposited from a bank account matching the name below.): %0a`;

  // const copyText = `Please add money to the following beneficiary to make investments on Tap Invest. (Note that money should be deposited from a bank account matching the name below.):
  // Name: ${partnerDetails?.name}
  // Bank Name: ${bankName}
  // Branch Name: ${branchDetails}
  // Account Number: ${accountNumber}
  // IFSC Code: ${ifscCode}`;

  // const waText = `Please add money to the following beneficiary to make investments on Tap Invest. (Note that money should be deposited from a bank account matching the name below.): %0a
  // Name: ${partnerDetails?.name} %0a
  // Bank Name: ${bankName} %0a
  // Branch Name: ${branchDetails} %0a
  // Account Number: ${accountNumber} %0a
  // IFSC Code: ${ifscCode}`;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000); // Reset after 3 seconds
      })
      .catch((err) => console.error('Failed to copy:', err));
  };

  return (
    <div>
      <img
        width={258}
        height={250}
        src={OnboardingSuccess}
        alt="Partner onboarding success flag"
        className="mx-auto flex justify-center mb-6"
      />
      <h3 className="text-stone-900 text-lg text-center font-semibold md:text-2xl mb-3">
        New Partner Added Successfully!
      </h3>
      <p className="text-stone-600 text-center max-w-md mx-auto">
        Congratulations, {partnerDetails?.profile?.name || 'This partner'} has been added to your
        partner list. You can now invest on their behalf.
      </p>

      <div className="bg-white relative border border-gray-200 min-h-16 mt-8 shadow-sm rounded-xl px-6 py-5 flex flex-col justify-between gap-3">
        <div className="flex items-center justify-between">
          <h4 className="text-sm text-gray-600">Name:</h4>
          <h3 className="text-sm font-medium">{partnerDetails?.profile?.name || '-'}</h3>
        </div>
        {/* <div className="flex items-center justify-between">
                    <h4 className="text-sm text-gray-600">Bank Name:</h4>
                    <h3 className="text-sm font-medium">{bankName}</h3>
                </div>
                <div className="flex items-center justify-between">
                    <h4 className="text-sm text-gray-600">Branch Name:</h4>
                    <h3 className="text-sm font-medium">{branchDetails}</h3>
                </div>
                <div className="flex items-center justify-between">
                    <h4 className="text-sm text-gray-600">Account Number:</h4>
                    <h3 className="text-sm font-medium">{accountNumber}</h3>
                </div>
                <div className="flex items-center justify-between">
                    <h4 className="text-sm text-gray-600">IFSC Code:</h4>
                    <h3 className="text-sm font-medium">{ifscCode}</h3>
                </div> */}
        <div className="uppercase flex items-center gap-1 absolute -top-3 inset-x-0 mx-auto w-fit rounded-full font-bold px-2 py-0.5 text-[10px] text-green-600 bg-green-100">
          <HowToRegRoundedIcon className="text-green-600 !text-sm" fontSize="small" /> Kyc completed
        </div>
        <button
          onClick={copyToClipboard}
          className="uppercase flex items-center gap-1 w-fit absolute -bottom-4 inset-x-0 mx-auto text-[10px] text-stone-400 font-semibold px-3 py-2 rounded-lg bg-white border border-gray-200"
        >
          {copied ? (
            <CheckRoundedIcon className="!text-sm text-stone-400" />
          ) : (
            <ContentCopyRoundedIcon className="!text-sm text-stone-400" />
          )}{' '}
          {copied ? 'Copied!' : 'Copy to Clipboard'}
        </button>
      </div>
      <div className="mt-8 flex items-center gap-3 justify-center">
        <a
          href={`https://api.whatsapp.com/send?text=${waText}`}
          target="_blank"
          className="bg-green-700 text-white shadow flex items-center gap-2 text-sm rounded-xl font-semibold px-4 h-10"
          rel="noreferrer"
        >
          Share Details via WhatsApp
        </a>
        <button
          onClick={() => {
            setIsPartnerDrawerOpen(null);
            handleStep(PARTNER_ONBOARDING_PAGE_TYPE.FIRST_STEP);
          }}
          className="bg-white text-slate-600 text-sm font-semibold shadow h-10 px-4 rounded-xl"
        >
          I'll do it later
        </button>
      </div>
    </div>
  );
};

FinalStep.displayName = 'FinalStep';

export default FinalStep;

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

const NameAndKYC = ({
  name = '-',
  memberLevel = 'SECONDARY_MEMBER',
  type,
  familyName,
  kycStatus,
}) => {
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'left'}>
      <Box display={'flex'} alignItems={'left'} justifyContent={'flex-start'}>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '500',
          }}
        >
          {' '}
          {name}{' '}
        </Typography>
      </Box>
      <Box
        className="!w-fit mt-1"
        color={'red'}
        display={'flex'}
        alignItems={'left'}
        height={25}
        borderRadius={50}
        sx={{ background: '#FEE2E2', textTransform: kycStatus ? 'capitalize' : 'uppercase' }}
        justifyContent={'flex-start'}
      >
        <Box>
          <WarningRoundedIcon
            sx={{
              height: '15px',
              width: '15px',
              marginLeft: '10px',
            }}
          />
        </Box>
        <Box>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            mr={1}
            mt={0.5}
            ml={0.5}
            sx={{
              lineHeight: '1.5',
              fontSize: '10px',
              fontWeight: '400',
            }}
          >
            {kycStatus ? 'Contract Pending' : 'Kyc pending'}
          </Typography>
        </Box>
      </Box>
      {type === 'family'
        ? memberLevel === 'PRIMARY_MEMBER' && (
            <Box
              className="uppercase !w-fit mt-1"
              color={'green'}
              display={'flex'}
              alignItems={'left'}
              minHeight={25}
              padding={0.25}
              borderRadius={50}
              sx={{ background: '#1665341F' }}
              justifyContent={'flex-start'}
            >
              <Box>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  mr={1}
                  mt={0.5}
                  ml={0.5}
                  sx={{
                    fontSize: '10px',
                    fontWeight: '500',
                  }}
                >
                  Primary Account
                </Typography>
              </Box>
            </Box>
          )
        : type === 'client' &&
          familyName && (
            <Box
              className="uppercase !w-fit mt-1"
              color={'green'}
              display={'flex'}
              alignItems={'left'}
              minHeight={25}
              padding={0.25}
              borderRadius={50}
              sx={{ background: '#1665341F' }}
              justifyContent={'flex-start'}
            >
              <Box>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  mr={1}
                  mt={0.5}
                  ml={0.5}
                  sx={{
                    fontSize: '10px',
                    fontWeight: '500',
                  }}
                >
                  {familyName}
                </Typography>
              </Box>
            </Box>
          )}
    </Box>
  );
};

export default NameAndKYC;
